.session-timeout {
    &__timer-model {
        padding: 16px;

        &__text {
            text-align: center;
        }

        &__button {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            gap: 16px;
            margin: 0;

            &__delete {
                background-color: var(--accent-red) !important;
            }
        }
    }
}