.popover-section {
	position: relative;
}

.popover-section button {
	font-size: 16px !important;
}

.popover {
	position: absolute;
	z-index: 1010;
	display: block;
	margin-top: -0.5rem;
	left: 100%;
	width: 276px;
}

.popover .arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}

.popover-header {
	padding: 0.5rem 0.75rem;
	margin: 0;
	font-size: 1rem;
	background-color: #f7f7f7;
	border-bottom: 1px solid #ebebeb;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}

.popover-body {
	padding: 0.5rem 0.75rem;
	color: #212529;
}

.popover-overlay {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
}