* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a:hover {
  cursor: pointer;
}

body {
  font-family: 'Circular Std Book', sans-serif;
  background-color: transparent;
}
iframe {
  display: none;
}

button {
  background-color: var(--primary-blue) !important;
  border-color: var(--primary-blue) !important;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.login-layout {
  position: absolute;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 50px 0px;
}

.login-centered {
  position: absolute;
  left: 0;
  right: 0;
  padding: 50px 0px;
}

.login-form {
  position: relative;
  max-width: 400px;
  width: 100%;
  padding: 15px;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.login-bottom-bar {
  position: absolute;
  bottom: 0;
  background: var(--accent-orange);
  right: 0;
  left: 0;
  margin: 0;
  padding: 10px 0px;
}

.login-bottom-bar a {
  color: #ffffff;
  font-weight: 500;

}

.login-form__logo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 12px; */
  margin: 35px 0px;
  gap: 20px;
}


.brand-name {
  font-size: 20px;
  font-weight: 500;
  animation: letterSpacingAnimation 3s linear;
}

@keyframes letterSpacingAnimation {
  10% { letter-spacing: 0px; }
  18% { letter-spacing: 2px; }
  92% { letter-spacing: 2px; }
  100% { letter-spacing: 0px; }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

hr {
  border-top: 1px solid var(--light-gray);
}

button:disabled {
  opacity: 0.8;
}

.switch-input {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-input.disabled {
  pointer-events: none;
  opacity: 0.8;
}

.switch-input input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-input-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch-input-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .switch-input-slider {
  background-color: var(--primary-blue);
}

input:focus + .switch-input-slider {
  box-shadow: 0 0 1px var(--primary-blue);
}

input:checked + .switch-input-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switch-input-slider.round {
  border-radius: 34px;
}

.switch-input-slider.round:before {
  border-radius: 50%;
}

