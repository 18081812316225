.custom-loader {
  display: flex;
  justify-content: center;

  &__loader {
    position: relative;

    &__image {
      position: absolute;
      top: 10px;
      left: 13.5px;
    }
  }
}

.loader-gif-img {
  width: 30px !important;
  height: 30px !important;
}

.full-loader {
  padding: 15vh 0px;
}

.logo-loader {
  animation: fadeInOut 3s infinite;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.8; }
  50% { opacity: 1; }
}

@keyframes chase {
  100% { transform: rotate(360deg); }
}

@keyframes chase-dot {
  80%, 100% { transform: rotate(360deg); }
}

@keyframes chase-dot-before {
  50% {
    transform: scale(0.4);
  } 100%, 0% {
    transform: scale(1.0);
  }
}

.chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: chase 2.5s infinite linear both;
}

.chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: chase-dot 2.0s infinite ease-in-out both;
}

.chase-dot:before {
  content: '';
  display: block;
  width: 20%;
  height: 20%;
  background-color: #004182;
  border-radius: 100%;
  animation: chase-dot-before 2.0s infinite ease-in-out both;
}

.chase-dot:nth-child(1) { animation-delay: -1.1s; }
.chase-dot:nth-child(2) { animation-delay: -1.0s; }
.chase-dot:nth-child(3) { animation-delay: -0.9s; }
.chase-dot:nth-child(4) { animation-delay: -0.8s; }
.chase-dot:nth-child(5) { animation-delay: -0.7s; }
.chase-dot:nth-child(6) { animation-delay: -0.6s; }
.chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.chase-dot:nth-child(6):before { animation-delay: -0.6s; }
